/**
 * Created by osirvent on 30/03/2017.
 */
angular
    .module('annexaApp')
    .factory('SecFactory',['$rootScope', '$q', '$http', '$filter', 'RestService', 'CommonService', 'Language', 'TableFilter', 'AnnexaModalFactory', 'DTColumnBuilder', 'dialogs', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'HelperService', 'SecModals', '$state', 'CustomFieldFactory', 'GlobalDataFactory', 'DccumentsFactory', function($rootScope, $q, $http, $filter, RestService, CommonService, Language, TableFilter, AnnexaModalFactory, DTColumnBuilder, dialogs, globalModals, AnnexaFormlyFactory, DialogsFactory, HelperService, SecModals, $state, CustomFieldFactory, GlobalDataFactory, DccumentsFactory) {
        var factory = {};

        //region Propietats

        factory.sections = [];
        factory.languageColumn = Language.getActiveColumn();
        factory.users = [];
        factory.proposal = {};
        factory.session = {};
		factory.isAllTransfered = false;
        factory.secDocumentTypes = [];
        factory.organAgendaPointTypes = [];
        factory.newProposalOrgans = [];
        factory.positions = [];
        factory.politicalParties = [];
        factory.thirdsAdded = [];
        factory.newProposalOrgan = undefined;
        factory.secTemplatesDocumentType = undefined;
        factory.proposalIsInOrder = false;
        factory.proposalSessionsExecuteOrder = undefined;
        factory.decrees = [];
        factory.decree = {};
        factory.decreeNotifications = [];
		factory.canAddedSecConfiguration = false;
		factory.showSessionSecNotifications = {DOCUMENT: false, SIGN: false, NOTIFICATION: false, CLOSE_TRANSFER: false};
		factory.proposalsInStatusSecNotifications = undefined;
		factory.sectionsUser = DccumentsFactory.sectionsUser;
		
        factory.secTemplateTypes = [
        	{ id: 'SAC', name: $filter('translate')('global.sec.literals.act'), acronym: 'A'},
        	{ id: 'SCR', name: $filter('translate')('global.sec.literals.certificate'), acronym: 'C'},
        	{ id: 'SCO', name: $filter('translate')('global.sec.literals.announcement'), acronym: 'CO'},
        	{ id: 'SDI', name: $filter('translate')('global.sec.literals.opinion'), acronym: 'DI'},
        	{ id: 'SPA', name: $filter('translate')('global.sec.literals.preact'), acronym: 'PA'},
        	{ id: 'SPR', name: $filter('translate')('global.sec.literals.proposal'), acronym: 'P'},
        	{ id: 'STR', name: $filter('translate')('global.sec.literals.transfer'), acronym: 'T'},
        	{ id: 'SPT', name: $filter('translate')('global.sec.literals.pointExecutedOrder'), acronym: 'PT'},
        	{ id: 'SDR', name: $filter('translate')('global.sec.literals.decree'), acronym: 'SDR'},
        	{ id: 'SPD', name: $filter('translate')('global.sec.literals.proposalDecree'), acronym: 'SPD'},
			{ id: 'OTHER', name: $filter('translate')('global.literals.others'), acronym: 'OTHER'}
        ];
        factory.dossierTransactionSec = undefined;
        factory.proposalDossierTransaction = [];
        factory.proposalStates = [
            { id: 'DRAFT', name: $filter('translate')('global.sec.literals.status.DRAFT'), class: 'text-grey', icon: 'circle-o', style: 'text-grey' },
            { id: 'PENDING', name: $filter('translate')('global.sec.literals.status.PENDING'), class: 'text-blue', icon: 'circle-o', style: 'text-blue' },
            { id: 'REVIWED', name: $filter('translate')('global.sec.literals.status.REVIWED'), class: 'text-blue', icon: 'dot-circle-o', style: 'text-blue' },
            { id: 'VALIDATED', name: $filter('translate')('global.sec.literals.status.VALIDATED'), class: 'text-blue', icon: 'check', style: 'text-blue' },
            { id: 'ACCEPTED', name: $filter('translate')('global.sec.literals.status.ACCEPTED'), class: 'text-blue', icon: 'adjust', style: 'text-blue' },
            { id: 'AMENDMENT', name: $filter('translate')('global.sec.literals.status.AMENDMENT'), class: 'text-danger', icon: 'minus-circle', style: 'text-danger' },
            { id: 'MANAGEMENT', name: $filter('translate')('global.sec.literals.status.MANAGEMENT'), class: 'text-success', icon: 'check-circle', style: 'text-success' },
            { id: 'FINISHED', name: $filter('translate')('global.sec.literals.status.FINISHED'), class: 'text-success', icon: 'dot-circle-o', style: 'text-success' },
            { id: 'CANCELED', name: $filter('translate')('global.sec.literals.status.CANCELLED'), class: 'text-warn', icon: 'times-circle', style: 'text-warn' },
            { id: 'ARCHIVED', name: $filter('translate')('global.sec.literals.status.ARCHIVED'), class: 'text-grey', icon: 'folder', style: 'text-grey' }
        ];
        factory.sessionStates = [
            { id: 'PENDING', name: $filter('translate')('global.literals.PENDING'), icon: 'circle-o', style: 'text-blue'},
            { id: 'CONVENED', name: $filter('translate')('global.sec.literals.CONVENED'), icon: 'adjust', style: 'text-blue'},
            { id: 'STARTED', name: $filter('translate')('global.sec.literals.STARTED'), icon: 'circle', style: 'text-blue'},
            { id: 'EXECUTED', name: $filter('translate')('global.sec.literals.EXECUTED'), icon: 'check-circle', style: 'text-success'},
            { id: 'FINISHED', name: $filter('translate')('global.sec.literals.status.CLOSED'), icon: 'dot-circle-o', style: 'text-success' }
        ];
        factory.decreeStates = [
            { id: 'PENDING', name: $filter('translate')('global.sec.literals.status.PENDING'), class: 'text-blue', icon: 'circle-o', style: 'text-blue' },
            { id: 'PENDING_SIGN', name: $filter('translate')('global.documentState.PENDING_SIGN'), class: 'text-blue', icon: 'adjust', style: 'text-blue' },
            { id: 'SIGNED', name: $filter('translate')('global.documentState.SIGNED'), class: 'text-success', icon: 'check-circle', style: 'text-success' },
            { id: 'CANCELLED', name: $filter('translate')('global.sec.literals.status.CANCELLED'), class: 'text-warn', icon: 'times-circle', style: 'text-warn' },
			{ id: 'REJECTED', name: $filter('translate')('global.sec.literals.status.REJECTED'), class: 'text-danger', icon: 'minus-circle', style: 'text-danger' },
			{ id: 'ANNULLED', name: $filter('translate')('global.sec.literals.status.ANNULLED'), class: 'text-danger', icon: 'times-circle', style: 'text-danger' }
        ];
        factory.votationTypes = [
        	{ id: 'ORDINARY', name: $filter('translate')('global.sec.literals.ordinary')},
        	{ id: 'NOMINAL', name: $filter('translate')('global.sec.literals.nominal')},
        	{ id: 'SECRET', name: $filter('translate')('global.sec.literals.secret')}
        ];
        factory.votationTypesWithWeightedType = [
        	{ id: 'ORDINARY', name: $filter('translate')('global.sec.literals.ordinary')},
        	{ id: 'NOMINAL', name: $filter('translate')('global.sec.literals.nominal')},
        	{ id: 'SECRET', name: $filter('translate')('global.sec.literals.secret')},
        	{ id: 'WEIGHTED', name: $filter('translate')('global.sec.literals.weightedType')}
        ];
        factory.requiredVoteStates = [
            { id: 'APPROVED_SIMPLE_MAJORITY', name: $filter('translate')('global.sec.literals.voteType.APPROVED_SIMPLE_MAJORITY')},
            { id: 'APPROVED_ABSOLUTE_MAJORITY', name: $filter('translate')('global.sec.literals.voteType.APPROVED_ABSOLUTE_MAJORITY')},
            { id: 'APPROVED_QUALIFIED_TWO_THIRDS', name: $filter('translate')('global.sec.literals.voteType.APPROVED_QUALIFIED_TWO_THIRDS')}
        ];
        
        factory.requiredVoteStatesModify = [
        	{ id: 'NOT_APPROVED', name: $filter('translate')('global.sec.literals.voteType.NOT_APPROVED')},
            { id: 'APPROVED', name: $filter('translate')('global.sec.literals.voteType.APPROVED')}
        ];

        factory.publishStatus = [
            { id: 'WITHOUT', name: $filter('translate')('global.sec.literals.secNotificationPublishStatusWITHOUT') },
            { id: 'PENDING', name: $filter('translate')('global.sec.literals.secNotificationPublishStatusPENDING') },
            { id: 'PUBLISHED', name: $filter('translate')('global.sec.literals.secNotificationPublishStatusPUBLISHED') },
            { id: 'UNPUBLISHED', name: $filter('translate')('global.sec.literals.secNotificationPublishStatusUNPUBLISHED') }
        ];

        factory.secNotificationStatus = [
            { id: 'FINISH', name: $filter('translate')('global.sec.literals.secNotificationStatusFINISH') },
            { id: 'DOCUMENT', name: $filter('translate')('global.sec.literals.secNotificationStatusDOCUMENT') },
            { id: 'SIGN', name: $filter('translate')('global.sec.literals.secNotificationStatusSIGN') },
            { id: 'NOTIFICATION', name: $filter('translate')('global.sec.literals.secNotificationStatusNOTIFICATION') },
            { id: 'NOTIFICATIONSIGN', name: $filter('translate')('global.sec.literals.secNotificationStatusNOTIFICATIONSIGN') },
            { id: 'CLOSE_TRANSFER', name: $filter('translate')('global.sec.literals.secNotificationStatusCLOSE_TRANSFER') },
            { id: 'PENDING_CLOSE_TRANSFER', name: $filter('translate')('global.sec.literals.secNotificationStatusPENDING_CLOSE_TRANSFER') },
            { id: 'WITHOUT', name: $filter('translate')('global.sec.literals.secNotificationStatusWITHOUT') },
            { id: 'FINISH_WITHOUT', name: $filter('translate')('global.sec.literals.secNotificationStatusFINISH_WITHOUT') }
        ];
        
        //endregion

        //region Router resolves
        factory.loadSecSessions = function() {
            var promises = [];

            promises.push(RestService.findAll('OrganAgendaPointType'));

            return $q.all(promises)
                .then(function(data) {
                	factory.organAgendaPointTypes = JSOG.decode(data[0].data);
                }).catch(function(error) {
                    factory.organAgendaPointTypes = [];
                });
        }

        factory.loadSecSessionsView = function(id) {
            var promises = [];

            promises.push(RestService.findOne('Session', id));
			promises.push($http({ url: './api/sec/session/isAllTransfered/'+id, method: 'GET' }));
			promises.push($http({ url: './api/sec/sec_notification/getSecNotificationsConfiguration/session/'+id, methor:'GET'}));
            return $q.all(promises)
                .then(function (data) {
                    factory.session = JSOG.decode(data[0].data);
                    
                    var getOrganWithPermissions = true;
                	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
                		if(factory.session.organ && factory.session.organ.everyoneCanViewSessions === false) {
                			if(factory.session.organ.usersViewSessions && factory.session.organ.usersViewSessions.length > 0) {
                				var intersectedViewUsers = $linq(factory.session.organ.usersViewSessions).intersect($rootScope.LoggedUser, "(x,y) => y.user && x.id && y.user.id && y.user.id == x.id").toArray();
                    			if(intersectedViewUsers && intersectedViewUsers.length > 0) {
                    				getOrganWithPermissions = true;
                    			} else {
                    				getOrganWithPermissions = false;
                    			}
                			} else {
                				var intersectedProfiles = $linq(factory.session.organ.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
                        		if(intersectedProfiles && intersectedProfiles.length > 0){
                        			getOrganWithPermissions = true;
                        		}else{
                        			getOrganWithPermissions = false;
                        		}
                			}
                		} else {
                			getOrganWithPermissions = true;
                		}
                	}else{
                		getOrganWithPermissions = false;
                	}
                	
                	if(!getOrganWithPermissions) {
						$state.transitionTo('annexa.dashboard');
                	} else {
    					factory.isAllTransfered = JSOG.decode(data[1].data);
    					var secNot = JSOG.decode(data[2].data);
    					factory.showSessionSecNotifications = secNot.status;
    					factory.proposalsInStatusSecNotifications = secNot.proposalsInStatus;
                	}
                }).catch(function(error) {
                    factory.session = undefined;
					factory.isAllTransfered = false;
					factory.showSessionSecNotifications = {DOCUMENT: false, SIGN: false, NOTIFICATION: false, CLOSE_TRANSFER: false};
					factory.proposalsInStatusSecNotifications = undefined;
					DialogsFactory.error($filter('translate')('global.sec.literals.errorGetSession'));
					$rootScope.loadingState(false);
					$state.transitionTo('annexa.sec.sessions');
                });
        }

        factory.loadSecProposals = function () {
            var promises = [];

            promises.push($http({ url: './api/sec/sec_template/sec_template', method: 'GET' }));

            return $q.all(promises)
                .then(function(data) {
                    var decodedData = JSOG.decode(data[0].data);
                    factory.secDocumentTypes = decodedData.docTypes;
                }).catch(function(error) {
                    factory.secDocumentTypes = [];
                })
        }
        
        factory.loadSecDecrees = function(id) {
            var promises = [];

            promises.push(RestService.findAll('ProposalType'));
            return $q.all(promises)
                .then(function(data) {
                    factory.proposalTypes = JSOG.decode(data[0].data);
                }).catch(function (error) {
                    factory.proposalTypes = [];
                })
        }

        factory.loadSecProposalsEdit = function(id) {
            var promises = [];

            promises.push(RestService.findOne('Proposal', id));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/in_orders', 'GET'));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/sessions', 'GET'));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/proposalDossierTransaction', 'GET'));
            promises.push(RestService.call('./api/sec/proposal/'+id+'/decrees', 'GET'));
			promises.push($http({url: './api/sec/sec_notification/canAddedSecConfiguration/'+id, method: 'GET'}));
            return $q.all(promises)
                .then(function(data) {
                    factory.proposal = JSOG.decode(data[0].data);
                    factory.proposalIsInOrder = data[1];
                    factory.proposalSessionsExecuteOrder = data[2];
                    factory.proposalDossierTransaction = data[3];
                    factory.decrees = data[4];
					factory.canAddedSecConfiguration = JSOG.decode(data[5].data);
                }).catch(function (error) {
                    factory.proposal = undefined;
                    factory.proposalIsInOrder = false;
                    factory.proposalSessionsExecuteOrder = undefined;
                    factory.proposalDossierTransaction = [];
                    factory.decrees = [];
					factory.canAddedSecConfiguration = false;
                })
        }
        
        factory.loadSecDecreesEdit = function(id) {
            var promises = [];

            promises.push(RestService.findOne('Decree', id));
			promises.push(RestService.call('./api/sec/decree/getNotifications/'+id, 'GET'));
            return $q.all(promises)
                .then(function(data) {
                    factory.decree = JSOG.decode(data[0].data);
					factory.decreeNotifications = JSOG.decode(data[1]);
                }).catch(function (error) {
                    factory.decree = {};
					factory.decreeNotifications = [];
                })
        }
        factory.loadAdmin = function(origin) {
            var promises = [];

            if (origin == 'annexa.admin.sec_templates') {
                promises.push($http({ url: './api/sec/sec_template/sec_template', method: 'GET' }));
                return $q.all(promises)
                    .then(function (data) {
                        var decodedData = JSOG.decode(data[0].data);
                        factory.secDocumentTypes = decodedData.docTypes;
                    }).catch(function (error) {
                        factory.secDocumentTypes = [];
                    })
            } else if (origin == 'annexa.admin.sec_members_organ') {
                promises.push(RestService.findAll('PoliticalParty'));
                promises.push(RestService.findAll('Position'));
                promises.push($http({ url: './api/sec/organ_member_global/thirds_added', method: 'GET' }));
                return $q.all(promises)
                    .then(function (data) {
                        factory.politicalParties = JSOG.decode(data[0].data);
                        factory.positions = JSOG.decode(data[1].data);
                        factory.thirdsAdded = JSOG.decode(data[2].data);
                    }).catch(function (error) {
                        factory.politicalParties = [];
                        factory.positions = [];
                    })
            }
        }
        //endregion

        //region General
        factory.deleteSessionDocument = function(secDocument){
        	var deferred = $q.defer();
            $http({
                url: './api/sec/session/document/'+ secDocument.id,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.deleteMultipleSessionDocuments = function (documentsToDelete) {
            var deferred = $q.defer();

            $http({
                url: './api/sec/session/deleteMultipleDocumentsSession',
                method: 'POST',
                data: JSOG.encode(documentsToDelete)
            }).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };
        
        factory.addProposalOrgan = function(proposalId, organId, delegationTypeId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/'+ organId +  ((delegationTypeId)?'/' + delegationTypeId:''),
                method: 'POST'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.updateProposalOrgan = function(proposalId, proposalOrganId, newOrganId, delegationTypeId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/' + proposalOrganId +'/'+newOrganId +  ((delegationTypeId)?'/' + delegationTypeId:''),
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.deleteProposalOrgan = function(proposalId, removedIds){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/organs/' + CommonService.getParameterList(removedIds),
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.updateStateProposal = function(proposalId, state, secretariatObservations, disagreement, action){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/' + proposalId + '/state/' + state + '/' + secretariatObservations + '/' + ((disagreement != undefined)?disagreement:false) +'/'+ ((action != undefined)?action:'-'),
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.annulDecree = function(decreeId, state, secretariatObservations, amendingDecreeId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/decree/' + decreeId + '/state/' + state + '/' + secretariatObservations + '/' + amendingDecreeId,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
           
        factory.createDocumentModal = function(type, oldDocument, object_type, object, promise, multiDocs, proposalOrgan, archiveClassificationId) {
    		var deferred = promise ? $q.defer() : null;

            var errorNoTemplates = function() {
                DialogsFactory.error($filter('translate')('global.sec.literals.noTemplates'));

                if(promise) {
                    deferred.reject('error');
                }
            }
            if(DccumentsFactory.sectionsUser && DccumentsFactory.sectionsUser.length > 0){
                var createDocumentSubmit = function() {
                	var calculateCFTGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(ng){
                    			_.forEach(ng.groups, function(g){
                    				g.groups = calculateCFTGroups(g.newGroups); 
                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                    				groups.push(g);
                    			})
                    		})
                    	}
                    	return groups;
                    }
                	
                    var self = this;
                    var model = this.annexaFormly.model.modal_body;

                    if(model) {
                        var request = {
                            secTemplate: { id: model.secTemplate },
                            section: { id: model.section },
                            profile: { id: model.profile },
                            title: model.documentTitle,
                            languageColumn: Language.getActiveColumn(),
                            translations: RestService.getTranslationsForSend(),
                            oldDocument: this.extra.oldDocument,
                            multiDocs:multiDocs,
                            proposalOrgan: proposalOrgan,
                            doctype: { id: model.docType},
                            archiveClassification: ((model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id)?{id: model.archiveClassification.$selected.id}:undefined)
                        };
                        if (!DccumentsFactory.validDocumentName(request.title, model.docType)) {
                        	if(this.alerts){
                        		this.alerts.length = 0;
                        	}else{
                        		this.alerts = [];
                        	}
                        	this.alerts.push({msg: HelperService.getErrorMessageValidatePattern()});
                        }else{
    	                    var cf = CustomFieldFactory.getModelValues(model.documentCustomFields);
    	                    request.customFields = [];
    	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined,"x => x.id == "+model.docType);
    	        			if(documentType && cf && cf.length > 0) {
    	                        _.forEach(documentType.customFields, function(docTypeField) {
    	                        	var groupField = undefined;
    								if(self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup){
    									var docCFFields = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
    									if(docCFFields && docCFFields.fieldGroup){
    										groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
    									}
    								}
                                	var docCustomField = undefined;
                                	if(groupField && groupField.data && groupField.data.customField){
                                		docCustomField = angular.copy(groupField.data.customField);
                                	}else{
                                		docCustomField = angular.copy(docTypeField);	
                                	}
    								docCustomField.id = undefined;
    								docCustomField.customField = { id: docTypeField.customField.id };
    								docCustomField.documentType = { id: documentType.id };
    								docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
    	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
    	
    	                            if(cfValue) {
    	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
    	                                    var custom_field_selected = $linq(cf).where(function(x){
    	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
    	                                    ).toArray();
    	                                    if(custom_field_selected && custom_field_selected.length > 0){
    	                                        docCustomField.value = '[';
    	                                        _.forEach(custom_field_selected, function (item, key) {
    	                                            if(item.value) {
    	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
    	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
    	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
    	                                                    ).toArray();
    	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
    	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
    	                                                    }
    	                                                }
    	                                            }
    	                                        });
    	                                        docCustomField.value += ']';
    	                                    }
    	                                }else if(Array.isArray(cfValue.value)) {
    	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
    	                                        docCustomField.value = '[';
    	
    	                                        _.forEach(cfValue.value, function (item) {
    	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
    	                                        });
    	
    	                                        docCustomField.value += ']';
    	                                    } else {
    	                                        docCustomField.value = cfValue.value[0];
    	                                    }
    	                                } else {
    	                                    if(cfValue.value instanceof Date) {
    	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
    	                                    } else {
    	                                        docCustomField.value = cfValue.value;
    	                                    }
    	                                }
    	                            }
    	
    	                            request.customFields.push(docCustomField);
    	                        });
    	                    }
    	                    if(object_type) {
    	                    	if(object.id){
    	                    		request[object_type] = {id:object.id};                    		
    	                    	}else{
    	                    		request[object_type] = {id:object};
    	                    	}
    	                    }else if(object.proposal){
    	                        if(object.proposal.id){
    	                        	request.proposal = {id:object.proposal.id};
    	                        }else{
    	                        	request.proposal = {id:object.proposal};
    	                        }
    	                    }
    	
    	                    var result = { request: request, type: type, modal: self, originalObject: ((object.proposal)? object : null)};
    	
    	                    if(promise) {
    	                        deferred.resolve(result);
    	                    } else {
    	                        $rootScope.$broadcast('annexaSecDocumentFromTemplateCreated', result);
    	                    }
                        }
                    }
                }
    			
                var documentType = $rootScope.app.configuration.sec_document_types[type];

                if((documentType && documentType.id) || type == 'OTHER') {
                	var modal = angular.copy(globalModals.secDocFromTemplate);
                    modal.alerts = [];
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {documentCustomFields: {},origDocumentType:undefined};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.type = type;
                    modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.type = type;
                    if(object.hasOwnProperty('section')) {
                        modal.annexaFormly.model.modal_body.section = object.section.id;
                    }
                    if(object.hasOwnProperty('profile')) {
                        modal.annexaFormly.model.modal_body.profile = object.profile.id;
                    }

                    if(object.hasOwnProperty('extract')) {
                        modal.annexaFormly.model.modal_body.documentTitle = object.extract;
                    }
                    if(object.hasOwnProperty('sessionDateFirstConvene')) {
                    	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.sessionDate = object.sessionDateFirstConvene;
                    }
                    if(object.hasOwnProperty('organ') && object.organ && object.organ.acronym) {
                    	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.organId = object.organ.id;
                    	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.acronymOrgan = object.organ.acronym;
                    }
                    if(object.hasOwnProperty('archiveClassification')){
                    	if(object.archiveClassification && object.archiveClassification.id){
                    		var archiveClassificationAux = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined, "x => x.id == "+object.archiveClassification.id);
                    		if(archiveClassificationAux){
    	                		modal.annexaFormly.model.modal_body.archiveClassification = {
    		        				$selected:{
    	                                id: archiveClassificationAux.id,
    	                                title: archiveClassificationAux[Language.getActiveColumn()]
    	                            }
    	                		};
                    		}else{
                    			modal.annexaFormly.model.modal_body.archiveClassification = {};
                    		}
    	                }else{
    	                	modal.annexaFormly.model.modal_body.archiveClassification = {};
    	                }
                    }else if(archiveClassificationId){
    					var archiveClassificationAux = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined, "x => x.id == "+archiveClassificationId);
                		if(archiveClassificationAux){
                    		modal.annexaFormly.model.modal_body.archiveClassification = {
    	        				$selected:{
                                    id: archiveClassificationAux.id,
                                    title: archiveClassificationAux[Language.getActiveColumn()]
                                }
                    		};
                		}else{
                			modal.annexaFormly.model.modal_body.archiveClassification = {};
                		}
    				}
                    modal.annexaFormly.fields[0].fieldGroup[5].templateOptions.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());

                    modal.annexaFormly.options = {};
                    modal.extra = { oldDocument: oldDocument };
                    modal.annexaFormly.options.formState = {readOnly: false};

                    AnnexaFormlyFactory.showModal("modalCreateSecDocument", modal, createDocumentSubmit, false);
                } else {
                    errorNoTemplates();
                }

                if(promise) {
                    return deferred.promise;
                }
        	}else{
        		DialogsFactory.error($filter('translate')('global.errors.noUserSection'), $filter('translate')('DIALOGS_ERROR_MSG'));
        		if(promise) {
                    deferred.reject('error');
                }
        	}
        }
        factory.createDocumentModalDecrees = function(type, oldDocument, object_type, object, promise, multiDocs, proposalOrgan) {
            var deferred = promise ? $q.defer() : null;

            var errorNoTemplates = function() {
                DialogsFactory.error($filter('translate')('global.sec.literals.noTemplates'));

                if(promise) {
                    deferred.reject('error');
                }
            }

            var createDocumentSubmit = function() {
            	var calculateCFTGroups = function(newGroups){
                	var groups = [];
                	if(newGroups){
                		_.forEach(newGroups, function(ng){
                			_.forEach(ng.groups, function(g){
                				g.groups = calculateCFTGroups(g.newGroups); 
                				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                				groups.push(g);
                			})
                		})
                	}
                	return groups;
                }
            	
                var self = this;
                var model = this.annexaFormly.model.modal_body;

                if(model) {
                    var request = {
                        secTemplate: { id: model.secTemplate },
                        title: model.documentTitle,
                        languageColumn: Language.getActiveColumn(),
                        translations: RestService.getTranslationsForSend(),
                        oldDocument: this.extra.oldDocument,
                        multiDocs:multiDocs,
                        proposalOrgan: proposalOrgan,
                        doctype: { id: model.docType}
                    };
                    if (!DccumentsFactory.validDocumentName(request.title, model.docType)) {
                    	if(this.alerts){
                    		this.alerts.length = 0;
                    	}else{
                    		this.alerts = [];
                    	}
                    	this.alerts.push({msg: HelperService.getErrorMessageValidatePattern()});
                    }else{
	                    var cf = CustomFieldFactory.getModelValues(model.documentCustomFields);
	                    request.customFields = [];
	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined,"x => x.id == "+model.docType);
	        			if(documentType && cf && cf.length > 0) {
	                        _.forEach(documentType.customFields, function(docTypeField) {
	                        	var groupField = undefined;
								if(self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup){
									var docCFFields = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
									if(docCFFields && docCFFields.fieldGroup){
										groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
									}
								}
                            	var docCustomField = undefined;
                            	if(groupField && groupField.data && groupField.data.customField){
                            		docCustomField = angular.copy(groupField.data.customField);
                            	}else{
                            		docCustomField = angular.copy(docTypeField);	
                            	}
								docCustomField.id = undefined;
								docCustomField.customField = { id: docTypeField.customField.id };
								docCustomField.documentType = { id: documentType.id };
								docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
	
	                            if(cfValue) {
	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
	                                    var custom_field_selected = $linq(cf).where(function(x){
	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
	                                    ).toArray();
	                                    if(custom_field_selected && custom_field_selected.length > 0){
	                                        docCustomField.value = '[';
	                                        _.forEach(custom_field_selected, function (item, key) {
	                                            if(item.value) {
	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
	                                                    ).toArray();
	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
	                                                    }
	                                                }
	                                            }
	                                        });
	                                        docCustomField.value += ']';
	                                    }
	                                }else if(Array.isArray(cfValue.value)) {
	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
	                                        docCustomField.value = '[';
	
	                                        _.forEach(cfValue.value, function (item) {
	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
	                                        });
	
	                                        docCustomField.value += ']';
	                                    } else {
	                                        docCustomField.value = cfValue.value[0];
	                                    }
	                                } else {
	                                    if(cfValue.value instanceof Date) {
	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
	                                    } else {
	                                        docCustomField.value = cfValue.value;
	                                    }
	                                }
	                            }
	
	                            request.customFields.push(docCustomField);
	                        });
	                    }
	                    if(object_type) {
	                    	if(object.id){
	                    		request[object_type] = {id:object.id};                    		
	                    	}else{
	                    		request[object_type] = {id:object};
	                    	}
	                    }else if(object && object.proposal){
	                        if(object.proposal.id){
	                        	request.proposal = {id:object.proposal.id};
	                        }else{
	                        	request.proposal = {id:object.proposal};
	                        }
	                    }
	
	                    var result = { request: request, type: type, modal: self, originalObject: ((object && object.proposal)? object : null)};
	
	                    if(promise) {
	                        deferred.resolve(result);
	                    } else {
	                        $rootScope.$broadcast('annexaSecDocumentFromTemplateCreated', result);
	                    }
                    }
                }
            }
			
            var documentType = $rootScope.app.configuration.sec_document_types[type];

            if((documentType && documentType.id) || type == 'OTHER') {
            	var modal = angular.copy(globalModals.secDocDecreesFromTemplate);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {documentCustomFields: {},origDocumentType:undefined};
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.type = type;
                modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.type = type;
            	if(object && object.hasOwnProperty('sessionDateFirstConvene')) {
                	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.sessionDate = object.sessionDateFirstConvene;
                }
                if(object && object.hasOwnProperty('organ') && object.organ && object.organ.acronym) {
                	modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.organId = object.organ.id;
                	modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.acronymOrgan = object.organ.acronym;
                }
                modal.annexaFormly.options = {};
                modal.extra = { oldDocument: oldDocument };
                modal.annexaFormly.options.formState = {readOnly: false};

                AnnexaFormlyFactory.showModal("modalCreateSecDocument", modal, createDocumentSubmit, false);
            } else {
                errorNoTemplates();
            }

            if(promise) {
                return deferred.promise;
            }
        }
        //endregion

        //region Sesions
        var sessionOperation = function(id, type, method) {
            return RestService.call('./api/sec/session/' + id + '/' + type, method);
        }

        factory.endSession = function(id) {
            return sessionOperation(id, 'end', 'PUT');
        }

        factory.endSessionWithDate = function(id, endDate) {
            return sessionOperation(id, 'end/' + endDate, 'PUT');
        }

        factory.cancelStartSession = function(id) {
            return sessionOperation(id, 'start', 'DELETE');
        }

        factory.startSessionWithDate = function (id, startDate) {
            return sessionOperation(id, 'start/' + startDate, 'PUT')
        }

        factory.deleteSessionAttendee = function (id) {
            return RestService.call('./api/sec/session_attendee/' + id, 'DELETE');
        }

        factory.getSessionProposalsByType = function(id, type) {
            return RestService.call('./api/sec/session/' + id + '/proposals/' + type, 'GET');
        }
        //endregion

        //region Administració
        factory.addSecFooterClaim = function (model) {
            var deferrend = $q.defer();

            var formData = new FormData();
            angular.forEach(model, function (value, key) {
                formData.append(key, value);
            });

            $http({
                method: 'POST',
                url: './api/sec/footer_claim/footer_claim',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }
        
        factory.addSecTemplate = function (model, files) {
            var deferrend = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
                _.forEach(files, function(file, index){
                    formData.append('files', file);
                });
            }else{
                formData.append('files', files);
            }
            formData.append('model', new Blob([JSON.stringify(model)], {
                type: "application/json"
            }));

            $http({
                method: 'POST',
                url: './api/sec/sec_template/sec_template',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

		factory.createProposalOperation = function(proposalOperation){
        	var deferred = $q.defer();
            $http({
                url: './api/sec/proposal/' + proposalOperation.proposal.id + '/operation',
                method: 'POST',
                data: JSOG.encode(proposalOperation)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })


            return deferred.promise;
        }

		factory.deleteProposalOperation = function(operation){
        	var deferred = $q.defer();
            $http({
                url: './api/sec/proposal/operation/'+operation,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })


            return deferred.promise;
        }

		factory.haveSignedCertificate = function(propsId){
        	var deferred = $q.defer();
			if(propsId && propsId.length){
	            $http({
	                url: './api/sec/proposal/signedCertificates/'+ CommonService.getParameterList(propsId),
	                method: 'GET'
	            }).then(function (data) {
	                deferred.resolve(data.data);
	            }).catch(function (error) {
	                deferred.reject(error);
	            })
			}else{
				deferred.reject("No proposals");
			}

            return deferred.promise;
        }

		factory.activateOrdeactivateOrgan = function(organId){
        	var deferred = $q.defer();
			if(organId){
	            $http({
	                url: './api/sec/organ/activateOrdeactivate/'+ organId,
	                method: 'PUT'
	            }).then(function (data) {
	                deferred.resolve(data);
	            }).catch(function (error) {
	                deferred.reject(error);
	            })
			}else{
				deferred.reject("No organ");
			}

            return deferred.promise;
        }

		
		factory.getOrgansByOrganMemberId = function(organMemberId) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/organ_member_global/organsByOrganMember/'+ organMemberId,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

		factory.getSecNotificationsConfiguration = function(type, id) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/sec_notification/getSecNotificationsConfiguration/session/'+type+'/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.getSecNotificationsConfigurationProposalId = function(id) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/sec_notification/getSecNotificationsConfiguration/proposal/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.getSecNotificationsConfigurationProposal = function(type, id) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/sec_notification/getSecNotificationsConfiguration/proposal/'+type+'/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

		factory.generateSecNotificationDocuments = function(id, fromTemplateRequests) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/session/'+id+'/generateSecNotificationDocuments',
                method: 'POST',
				data: fromTemplateRequests
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

		factory.getSecNotificationsConfigurationSession = function(id) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/sec_notification/getSecNotificationsConfiguration/session/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

		factory.sendToSignSecNotificationDocuments = function(id, sendToSignDocuments) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/session/'+id+'/sendToSignSecNotificationDocuments',
                method: 'POST',
				data: sendToSignDocuments
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}

		factory.createNotificationsSecNotificationDocuments = function(id, sendNotifications) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/session/'+id+'/createNotificationsSecNotificationDocuments',
                method: 'POST',
				data: sendNotifications
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.createNotificationsSecTransactions = function(id, secNotifications) {
			var deferred = $q.defer();
            $http({
                url: './api/sec/session/'+id+'/createNotificationsSecTransactions',
                method: 'POST',
				data: secNotifications
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		factory.getUserProfiles = function(id) {
			var deferred = $q.defer();
            $http({
                url: './api/profiles/getUserProfileBysProfileId/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		factory.getUserProfilesByProfileId = function(id) {
			var deferred = $q.defer();
            $http({
                url: './api/profiles/getUserProfilesByProfileId/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.dtWithoutNotifications = function(id){
			var deferred = $q.defer();
            $http({
                url:  './api/sec/session/dtWithoutNotifications/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.publishSitesNotDone = function(id){
			var deferred = $q.defer();
            $http({
                url:  './api/sec/session/publishSitesNotDone/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.publishSitesNotDoneProposal = function(id){
			var deferred = $q.defer();
            $http({
                url:  './api/sec/session/publishSitesNotDoneProposal/'+id,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.getDossierThirdsAndSons = function(proposalIds, sendSecType){
			var deferred = $q.defer();
            $http({
                url:  './api/sec/proposal/getDossierThirds/'+ CommonService.getParameterList(proposalIds) + '/' + sendSecType,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
		}
		
		factory.getTranslatedError = function(errorMessage){
			var error = '';
			if(errorMessage){
				if(errorMessage == 'No Required Custom Field Value data received in creation') {
                    error = 'global.tram.errors.requiredForEnd'
                }else if(errorMessage == 'No transaction data reciveid in creation') {
                    error = 'global.tram.errors.noTransaction'
                }else if(errorMessage == 'No dossierTransactions data reciveid in update') {
                    error = 'global.tram.errors.noTransactions'
                }else if(errorMessage == 'No translations data reciveid in update') {
                    error = 'global.tram.errors.noTranslations'
                }else if(errorMessage == 'Have pending sing or new documents') {
                    error = 'global.errors.pendingSingDocumentsOrNew';
                }else if(errorMessage == 'Error make definitive document') {
                    error = 'global.tram.errors.noDefinitiveDocument';
                }else if(errorMessage == 'error-SelectOne not found') {
                    error = 'global.tram.errors.selectOneError'
                }else if(errorMessage == 'error-MissingProfile not found') {
                    error = 'global.tram.errors.missingProfileError'
                }else if(errorMessage == 'error-MissingTransactionType not found') {
                    error = 'global.tram.errors.missingTransactionType'
                }else if(errorMessage == 'error-MissingResponsibleUser not found') {
                    error = 'global.tram.errors.missingResponsibleUser'
                }else if(errorMessage == 'error-SelectMultipleOpen not found') {
                    error = 'global.tram.errors.selectMultipleError'
                }else if(errorMessage == 'error-requestCompleteDossier-only-finally-transaction not found' || error.message == 'error-pendingTransactions not found') {
                    error = 'global.tram.errors.onlyFinallyTransaction';
                }else if(errorMessage == 'error-requestCompleteDossier-no-responsible not found') {
                    error = 'tram.errors.noResponsible';
                }else if(errorMessage == 'error-requestCompleteDossier-only-responsible not found') {
                    error = 'global.tram.errors.onlyResponsible';
                }else if(errorMessage == 'error-requestCompleteDossier-no-final-transaction not found') {
                    error = 'global.tram.errors.noFinallyTransaction';
                }else if(errorMessage == 'error-requestCompleteDossier-final-transaction-subprocedure not found') {
                    error = 'global.tram.errors.finallyTransactionSubprocedure';
                }else if(errorMessage == 'error-requestCompleteDossier-only-one-finally-transaction-type not found') {
                    error = 'global.tram.errors.onlyOneFinallyTransaction';
                }else if(errorMessage == 'error-requestCompleteDossier-no-responsible-profile not found') {
                    error = 'global.tram.errors.noProfileResponsibleFinallyTransaction';
                }else if(errorMessage == 'error-requestCompleteDossier-have-open-transactions not found') {
                    error = 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions';
                }else if(errorMessage == 'error-requestCompleteDossier-no-responsible-user-transaction') {
                    error = 'global.tram.errors.noResponsibleUserTransaction';
                }else if(errorMessage == 'error-requestCompleteDossier-profileNotFound not found') {
					error = 'global.tram.errors.profileNotFound';
				}else if(errorMessage == 'error-requestCompleteDossier-list-profileNotFound not found') {
                    error = 'global.tram.errors.profileNotFound';
				}else if(errorMessage.toUpperCase().indexOf('ALFRESCO') != -1) {
                	error = 'global.literals.errorSaveGD';
            	} else if(errorMessage.toUpperCase() == 'Template Exception'.toUpperCase()){
					error = 'global.tram.errors.processTemplate';
				} else if(errorMessage == "Unknown Host") {
                    error = $filter('translate')('global.errors.unknowHostError')+(($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sign_util_configuration && $rootScope.app.configuration.sign_util_configuration.timeStampUrl)?$rootScope.app.configuration.sign_util_configuration.timeStampUrl:'');
                }else if(errorMessage == "Error document can't convert to PDF"){
                    error = 'global.errors.cantConvertToPDF';
                }else if(errorMessage == "Error document protected PDF"){
                    error = 'global.errors.protectedPDF';
                }else if(errorMessage == "Error document in book and circuit is not correct"){
                    error = 'global.errors.documentBookAndCircuitError';
                }else if(errorMessage == "Error book state modify"){
                    error = 'global.errors.errorModifyBookState';
                }else if(errorMessage == "There is already a signing process"){
                    error = 'global.errors.errorAlreadySigningProcess';    
                }else if(errorMessage == "Error document in decree and circuit is not correct"){
                    error = 'global.errors.documentDecreeAndCircuitError';
                }else if(errorMessage == "Error decre state modify"){
                    error = 'global.errors.documentDecreeAndCircuitError';
                }else if(errorMessage == "Is not valid document from template not found"){
                    error = 'global.errors.documentNotValidFromTemplateError';
                }else if(errorMessage == 'No notification type'){
						error = 'global.sec.errors.noNotificationType';
            	}else if(errorMessage == 'channelNoValidSendNotifications'){
            		error = 'global.errors.channelNoValidSendNotifications';
				}else if(errorMessage == 'No documents to this third notification'){
            		error = 'global.errors.noDocumentsForThird';
            	}else if(errorMessage.toUpperCase() == 'Template Exception'.toUpperCase()){
					error = 'global.tram.errors.processTemplate';
				}else if(errorMessage === "Is not valid document from template not found"){
					error = 'global.documents.definitiveNotificationNoValidError';
				}else if(errorMessage === "No thirds selected in data received" || errorMessage == "No third selected in dossier"){
					error = 'global.literals.noThirds';
				}else{
					error = 'global.sec.literals.errorSecNotfication';
            	}
			}
			return ((error)?' '+$filter('translate')('DIALOGS_ERROR')+': '+$filter('translate')(error):'');
		}
		factory.showErrors = function(secNotOk, data, okMessage, errorMessage, showOnlyIfError){
			var keys = Object.keys(data);
			var isError = false;
			var ok = '';
			if(secNotOk && secNotOk.length > 0){
				var secsOK = [];
				if(keys && keys.length > 0){
					secsOk = $linq(secNotOk).except(keys,"(x,y) => y.relatedProposal && y.relatedProposal.id == x").toArray();
				}else{
					secsOk = secNotOk;
				}
				if(secsOk && secsOk.length > 0){
					 ok = $filter('translate')(okMessage)+':</br>';
					_.forEach(secsOk, function(key){
						if(key && key.relatedProposal && key.relatedProposal.proposalNumber){
							ok += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+' '+key.relatedProposal.proposalNumber+'</br>';
						}else{
							ok += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+'</br>';
						}
					});
				}
			}
			var error = '';
			if(keys && keys.length > 0){
				error = $filter('translate')(errorMessage)+':</br>';
				_.forEach(keys, function(key){
					var secN = $linq(secNotOk).firstOrDefault(key, "x => x.relatedProposal && x.relatedProposal.id == "+key);
					if(secN && secN.relatedProposal && secN.relatedProposal.proposalNumber){
						error += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+': '+secN.relatedProposal.proposalNumber+ factory.getTranslatedError(data[key])+'</br>';
					}else{
						error += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+': '+secN+ factory.getTranslatedError(data[key])+'</br>';
					}
					isError = true;
				});
			}
			if((showOnlyIfError && isError) || (!showOnlyIfError)) {
				DialogsFactory.notify(ok+((ok && error)?'</br></br>':'')+error);
			} else {
				$state.reload();
			}
		}
		
		factory.getAllProposalIds = function(filter, filterAux){
        	var deferrend = $q.defer();
            $http({
                method: 'POST',
                url: './api/sec/proposal/getAllIds',
                data:JSOG.encode({filter:angular.toJson(filter), additionalFilter:angular.toJson(filterAux)})
            }).success(function (data, status) {
            	if(data && data.length > 0){
            		deferrend.resolve(data);
            	}else{
            		deferrend.resolve([]);
            	}
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }
		factory.updateStateProposals = function(proposals, secretariatObservations, disagreement, action){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/updateStateProposals/' + secretariatObservations + '/' + ((disagreement != undefined)?disagreement:false) +'/'+ ((action != undefined)?action:'-'),
                method: 'PUT',
                data: proposals
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
		factory.getSelectedProposalsList = function(proposalSelectedRows, state){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/proposal/returnListProposalSelected/' + state,
                method: 'POST',
                data: proposalSelectedRows
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
		factory.getPoliticalPartyNumberOfVotes = function(partyId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/political_party/getPoliticalPartyNumberOfVotes/' + partyId,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
		factory.setSpokesPerson = function(memberId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/organ_member/setSpokesPerson/' + memberId,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
		
		factory.getJobSendSessionAvaBySessionId = function(sessionId){
        	var deferred = $q.defer();

            $http({
                url: './api/sec/job_send_session_ava/getBySessionId/' + sessionId,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion
        return factory;
    }]);